// Galerie Jomie Pâtisserie
jQuery(document).ready(function () {
    jQuery("#jomie_gallery").nanogallery2({
        locationHash: true,
        itemsBaseURL: 'https://cdn.shopify.com/s/files/1/0551/3048/8888/files/',
        items:[
            { src: 'creation00057.jpg', title: 'Feuille dorée'},
            { src: 'creation00008.jpg', title: 'Noces Emeraude 1'},
            { src: 'creation00028.jpg', title: 'Noces Emeraude 2'},
            // { src: 'creation00061.jpg', title: 'Chat 1'},
            { src: 'creation00142.jpg', title: 'Chats 1'},
            { src: 'https://cdn.shopify.com/videos/c/o/v/0186c017a4b9424b8287ef8e97175cad.mp4', srct: 'creation00056.jpg', title: 'Sac à main MK'},
            { src: 'creation00086.jpg', title: 'Gucci'},
            { src: 'creation00093.jpg', title: 'Rolex'},
            { src: 'creation00098.jpg', title: 'Chanel 1'},
            { src: 'creation00116.jpg', title: 'Chanel 2'},
            { src: 'creation00110.jpg', title: 'Talon Aiguille'},
            { src: 'creation00015.jpg', title: 'Concept Mariage'},
            { src: 'creation00030.jpg', title: 'Drapé Fleuri 1'},
            { src: 'creation00005.jpg', title: 'Drapé Fleuri 2'},
            { src: 'creation00004.jpg', title: 'Drapé Fleuri 3'},
            { src: 'creation00023.jpg', title: 'Bouquet'},
            { src: 'creation00072.jpg', title: 'Fleurs'},
            { src: 'creation00059.jpg', title: 'Cadre Photo'},
            { src: 'creation00064.jpg', title: 'Baby Shower 1'},
            { src: 'creation00063.jpg', title: 'Baby Shower 2'},
            { src: 'creation00103.jpg', title: 'Baby Shower 3'},
            { src: 'creation00095.jpg', title: 'Oursonne 1'},
            { src: 'creation00108.jpg', title: 'Oursonne 2'},
            { src: 'creation00137.jpg', title: 'Ourson 3'},
            { src: 'creation00148.jpg', title: 'Ourson 4'},
            { src: 'creation00089.jpg', title: 'Lion 1'},
            { src: 'creation00102.jpg', title: 'Lion 2'},
            { src: 'creation00117.jpg', title: 'Lion 3'},
            { src: 'creation00101.jpg', title: 'Petit Train'},
            { src: 'creation00124.jpg', title: 'Dino'},
            { src: 'creation00123.jpg', title: 'Robot'},
            { src: 'creation00105.jpg', title: 'Schtroumpfette'},
            { src: 'creation00096.jpg', title: 'Minnie Mouse 1'},
            { src: 'creation00143.jpg', title: 'Minnie Mouse 2'},
            { src: 'creation00130.jpg', title: 'Cendrillon'},
            { src: 'creation00131.jpg', title: "Buzz l'Éclair"},
            { src: 'creation00144.jpg', title: 'Monster Truck'},
            { src: 'creation00047.jpg', title: 'Panda'},
            { src: 'creation00062.jpg', title: 'Cinéma'},
            { src: 'creation00094.jpg', title: 'Cheval'},
            { src: 'creation00087.jpg', title: 'Pompier'},
            { src: 'creation00112.jpg', title: 'Football'},
            { src: 'creation00128.jpg', title: 'Basketball'},
            { src: 'creation00021.jpg', title: 'Sirènes 1'},
            { src: 'creation00099.jpg', title: 'Sirènes 2'},
            { src: 'creation00113.jpg', title: 'Licorne 1'},
            { src: 'creation00106.jpg', title: 'Licorne 2'},
            { src: 'creation00146.jpg', title: 'Licorne 3'},
            { src: 'creation00017.jpg', title: 'Licorne 4'},
            { src: 'creation00100.jpg', title: 'Licorne 5'},
            { src: 'creation00104.jpg', title: 'Licorne 6'},
            { src: 'creation00134.jpg', title: 'Biche'},
            { src: 'creation00073.jpg', title: 'Super Papa'},
            { src: 'creation00090.jpg', title: 'Bricoleur'},
            { src: 'creation00136.jpg', title: 'Gym'},
            { src: 'creation00084.jpg', title: 'Tesla'},
            { src: 'creation00010.jpg', title: 'Harley Davidson'},
            { src: 'creation00051.jpg', title: 'Momie Halloween'},
            { src: 'creation00022.jpg', title: 'Fraises Givrées'},

            { src: 'creation00067.jpg', title: 'Choco 1'},
            { src: 'creation00069.jpg', title: 'Choco 2'},
            { src: 'creation00075.jpg', title: 'Oréo 1'},
            { src: 'creation00077.jpg', title: 'Oréo 2'},

            { src: 'creation00066.jpg', title: 'Demon Slayer'},
            { src: 'creation00080.jpg', title: 'La Reine des Neiges'},
            { src: 'creation00118.jpg', title: "Pat'patrouille 1"},
            { src: 'creation00119.jpg', title: "Pat'patrouille 2"},
            { src: 'creation00122.jpg', title: "Pat'patrouille 3"},
            { src: 'creation00140.jpg', title: 'Peppa Pig'},
            { src: 'creation00121.jpg', title: 'Cocomelon'},
            { src: 'creation00071.jpg', title: 'Star Wars'},
            { src: 'creation00139.jpg', title: 'Stranger Things'},
            { src: 'creation00132.jpg', title: 'Minions'},
            { src: 'creation00097.jpg', title: 'Sonic'},
            { src: 'creation00078.jpg', title: 'Pokémon 1'},
            { src: 'creation00092.jpg', title: 'Pokémon 2'},
            { src: 'creation00052.jpg', title: 'Fortnite'},
            { src: 'creation00049.jpg', title: 'Genshin Impact'},
            { src: 'creation00141.jpg', title: 'Minecraft 1'},
            { src: 'creation00037.jpg', title: 'Minecraft 2'},
            { src: 'creation00048.jpg', title: 'Minecraft 3'},
            { src: 'creation00070.jpg', title: 'Minecraft 4'},
            { src: 'creation00120.jpg', title: 'Spiderman'},
            { src: 'creation00024.jpg', title: 'Batman 1'},
            { src: 'creation00127.jpg', title: 'Batman 2'},
            { src: 'creation00039.jpg', title: 'XS Flash'},
            { src: 'creation00026.jpg', title: 'Cars'},
            { src: 'creation00053.jpg', title: 'Le Roi Lion'},
            { src: 'creation00058.jpg', title: 'Roblox 1'},
            { src: 'creation00085.jpg', title: 'Roblox 2'},
            { src: 'creation00091.jpg', title: 'Roblox 3'},
            { src: 'creation00138.jpg', title: 'Maman Poule'},
            { src: 'creation00036.jpg', title: 'My Little Poney 1'},
            { src: 'creation00003.jpg', title: 'My Little Poney 2'},
            { src: 'creation00079.jpg', title: 'Bugs Bunny'},
            { src: 'creation00034.jpg', title: 'Shopkins 1'},
            { src: 'creation00046.jpg', title: 'Pyjamasque 1'},
            { src: 'creation00115.jpg', title: 'Pyjamasque 2'},

            { src: 'creation00088.jpg', title: 'Ferme'},
            { src: 'creation00031.jpg', title: 'Renard'},
            { src: 'creation00018.jpg', title: 'Astronaute'},
            { src: 'creation00035.jpg', title: 'Roses'},
            { src: 'creation00040.jpg', title: 'Poupée 1'},

            { src: 'creation00045.jpg', title: 'Voitures'},
            { src: 'creation00125.jpg', title: 'Crème au beurre 1'},
            { src: 'creation00126.jpg', title: 'Crème au beurre 2'},
            { src: 'creation00129.jpg', title: 'Crème au beurre 3'},
            { src: 'creation00135.jpg', title: 'Crème au beurre 4'},
            { src: 'creation00133.jpg', title: 'Crème au beurre 5'},
            { src: 'creation00147.jpg', title: 'Crème au beurre 6'},
            { src: 'creation00107.jpg', title: 'Chantilly 1'},
            { src: 'creation00109.jpg', title: 'Chantilly 2'},
            { src: 'creation00111.jpg', title: 'Chantilly 3'},
            { src: 'creation00065.jpg', title: 'Chantilly 4'},
            { src: 'creation00068.jpg', title: 'Chantilly 5'},
            { src: 'creation00074.jpg', title: 'Chantilly 6'},
            { src: 'creation00081.jpg', title: 'Chantilly 7'},
            { src: 'creation00082.jpg', title: 'Chantilly 8'},
            { src: 'creation00083.jpg', title: 'Chantilly 9'},
            { src: 'creation00114.jpg', title: 'Chantilly 10'},

            { src: 'creation00025.jpg', title: 'Pâques'},
            { src: 'creation00060.jpg', title: 'Elephant'},
            { src: 'creation00029.jpg', title: 'Dinosaures 1'},
            { src: 'creation00002.jpg', title: 'Dinosaures 2'},
            { src: 'creation00054.jpg', title: 'Dinosaures 3'},





            ],

        galleryTheme: {
            thumbnail: {
                background: 'transparent',
                backgroundImage: 'linear-gradient(315deg, rgba(254,251,247,1) 0%, rgba(249,244,237) 90%)',
                titleBgColor: 'transparent',
                titleShadow: '#FFF',
                descriptionShadow: '#FFF',
                titleColor: 'rgba(256,256,256,1)',
                borderRadius: 'transparent',
                borderColor: 'transparent'
            }
        },
        thumbnailWaitImageLoaded: true,
        thumbnailDisplayTransition: 'scaleUp',
        thumbnailHoverEffect2: 'toolsSlideDown|label_backgroundColor_rgba(249,243,236,0.8)_rgba(98,62,23,0.7)',
        thumbnailToolbarImage: { topRight: 'share'},
        thumbnailBorderVertical: 0,
        thumbnailBorderHorizontal: 0,
        thumbnailGutterWidth: 20,
        thumbnailGutterHeight: 20,
        thumbnailWidth: 200,
        thumbnailHeight: 300,
        thumbnailLabel: {
          valign: "center",
          position: 'overImage',
          titleFontSize: '1.1em',
        },
        viewerTheme: 'dark',
        viewerHideToolsDelay: 60000000,
        viewerGalleryTWidth: 60,
        viewerGalleryTHeight: 80,
        viewerToolbar: { display: true },
        viewerTools: {
            topLeft: 'pageCounter',
            topRight: 'closeButton'
        },
    });
});


